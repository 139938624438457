import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Clipboard from "react-clipboard.js";
import Content, { HTMLContent } from "../components/Content";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const ColorsTemplate = ({
  content,
  contentComponent,
  title
}) => {
  const PostContent = contentComponent || Content;
  const colors = [
    {
      "name": "3Play Blue",
      "id": "blue",
      "hexCode": "#007EB5",
      "hexCodeInverse": "#FFFFFF"
    },
    {
      "name": "Space Cadet",
      "id": "spaceCadet",
      "hexCode": "#273469",
      "hexCodeInverse": "#FFFFFF"
    },
    {
      "name": "Stay Golden(rod)",
      "id": "stayGoldenrod",
      "hexCode": "#FFCC66",
      "hexCodeInverse": "#000000"
    },
    {
      "name": "Coral Me Happy",
      "id": "coralMeHappy",
      "hexCode": "#FF7575",
      "hexCodeInverse": "#000000"
    },
    {
      "name": "Sky's the Limit",
      "id": "skysTheLimit",
      "hexCode": "#DEEFF5",
      "hexCodeInverse": "#000000"
    }
  ]
  return (
    <div className="container">
      <div className="column is-10 is-offset-1">
        <section className="section section--gradient">
          <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
            {title}
          </h1>
          <div className="row">
            {colors.map(color => (
              <div
                key={color.name}
                className="col-md-3"
                style={{
                  padding: "15px",
                  margin: "15px",
                  height: "160px",
                  width: "160px",
                  backgroundColor: color.hexCode,
                  color: color.hexCodeInverse,
                  fontSize: `20px`
                }}
              >
                {color.name}
                <br />
                {color.hexCode}
                <br />
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  delay={{ hide: 1000 }}
                  overlay={
                    <Popover id="popover-positioned-bottom">
                      <Popover.Title as="h3">{color.name} Copied!</Popover.Title>
                      <Popover.Content>
                        Hex Code {color.hexCode} has been copied to your clipboard!
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Clipboard
                    button-id={color.id}
                    button-title="Copy Hex Code"
                    button-type="button"
                    button-className="copy-hex-code-btn"
                    data-clipboard-text={color.hexCode}
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>&nbsp;Copy
                    Hex Code
                  </Clipboard>
                </OverlayTrigger>
              </div>
            ))}
          </div>
          <br />
          <PostContent content={content} />
        </section>
      </div>
    </div>
  );
};

ColorsTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  colors: PropTypes.array
};

const Colors = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        {/* <meta name="description" content={post.frontmatter.description} /> */}
      </Helmet>
      <ColorsTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>3Play Colors</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        colors={post.frontmatter.colors}
      />
    </Layout>
  );
};

Colors.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default Colors;

export const pageQuery = graphql`
  query ColorsByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;
